import React from 'react'

export default function Head(props) {
    return (
        <div>
            <div className="app-logo m-2"  />
            <h4 className='mb-0'>
                <div>{props.title}</div>
                <span>{props.subTitle}</span>
            </h4>
        </div>
    )
}
