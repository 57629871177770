import React from 'react'
import { Card } from 'reactstrap'

export default function Terms() {
    return (
        <div className='p-5 text-justify'>
            <p>
                LAST UPDATED: December 2, 2021
            </p>
            <p>
                IMPORTANT, READ CAREFULLY: YOUR USE OF AND ACCESS TO THE SERVICE (AND
                ASSOCIATED SOFTWARE) OF VOLUMEZ TECHNOLOGIES LTD AND ITS AFFILIATES
                (&ldquo;<strong>VOLUMEZ</strong>&rdquo;) IS CONDITIONED UPON YOUR COMPLIANCE WITH AND
                ACCEPTANCE OF THESE TERMS. PLEASE REVIEW THOROUGHLY BEFORE ACCEPTING.
            </p>
            <p>
                BY CLICKING THE &ldquo;I AGREE&rdquo; BUTTON/BOX, ACCESSING THE VOLUMEZ WEBSITE OR BY
                UTILIZING THE VOLUMEZ SERVICE YOU AGREE TO BE BOUND BY THESE TERMS AND
                CONDITIONS AND ALL EXHIBITS, ORDER FORMS, AND INCORPORATED POLICIES (THE
                &ldquo;<strong>TERMS</strong>&rdquo; OR THE &ldquo;<strong>AGREEMENT</strong>&rdquo;). THE
                VOLUMEZ SERVICE IS NOT AVAILABLE TO PERSONS WHO ARE NOT LEGALLY ELIGIBLE
                TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THESE
                TERMS AND OUR PRIVACY POLICY, THEN YOU ARE NOT ALLOWED TO USE THE
                SERVICE. IN ADDITION, YOU MAY NOT USE THE SERVICE IF YOU ARE A DIRECT
                COMPETITOR OF THE COMPANY. SHOULD YOU HAVE ANY QUESTIONS SURROUNDING
                THESE TERMS, PLEASE CONTACT INFO@VOLUMEZ.COM.
            </p>
            <p>
                Volumez will provide the Service, and you may access and use the Service,
                in accordance with this Agreement. If you order the Service through an
                online registration page, an order form or as part of the&#160;
                subscription flow that references this Agreement (each an &ldquo;<strong>Order
                    Form</strong>&rdquo;), the Order Form may contain additional terms and
                conditions and information regarding the Service you are ordering.
            </p>
            <ol type="1">
                <li>
                    <p>
                        <span class="underline">THE SERVICE</span>
                    </p>
                    <ol type="1">
                        <li>
                            <p>
                                Volumez provides data orchestration and block storage as a
                                service (the &ldquo;<strong>Service</strong>&rdquo;)
                            </p>
                        </li>
                        <li>
                            <p>
                                Volumez provides you a right and permission to use the Service
                                subject to a valid subscription, pursuant to the terms of the
                                Order Form.
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon your subscription to the Service, Volumez will grant you
                                access to the Service or certain parts of it, which will allow
                                you to add end-user accounts as set forth in the Order Form
                                (&ldquo;<strong>Permitted Users</strong>&ldquo;) and to control or manage
                                certain features of the Service. Permitted Users&rsquo; access to the
                                Service is limited and personal. You are responsible for actions
                                taken by Permitted Users or by anyone using your accounts and
                                passwords.
                            </p>
                        </li>
                        <li>
                            <p>
                                Volumez uses commercially reasonable efforts to maintain the
                                highest Service availability. However, Volumez cannot guarantee
                                that the Service will operate in an uninterrupted or error-free
                                manner. Volumez performs Service maintenance and uses
                                commercially reasonable effort to schedule system down-time to
                                off-peak hours and to avoid service interruptions and delays.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">DATA SECURITY</span>
                    </p>
                    <ol start="5" type="1">
                        <li>
                            <p>
                                Volumez will maintain reasonable physical and technical
                                safeguards to prevent unauthorized disclosure of or access to
                                content provided by the Permitted Users (the
                                &ldquo;<strong>Content</strong>&rdquo;). Volumez will not access, view or
                                process Content except (a) as provided for in this Agreement and
                                in Volumez&rsquo;s privacy policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;);
                                (b) as authorized or instructed by you, (c) as required to
                                perform its obligations under this Agreement; or (d) as required
                                by applicable law. Volumez has no other obligations with respect
                                to Content.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">YOUR UNDERTAKINGS</span>
                    </p>
                    <ol start="6" type="1">
                        <li>
                            <p>
                                You assume full responsibility for you and your Permitted Users&rsquo;
                                use of the Service in accordance with this Agreement and with
                                applicable local, state, federal, national and international
                                laws, regulations and treaties, and warrant that you have
                                obtained all rights in the Content to authorize Volumez to
                                process the Content as contemplated by the Agreement.
                            </p>
                        </li>
                        <li>
                            <p>
                                You will not, and ensure that your Permitted Users will not, use
                                the Service for any use or purpose that: (i) is obscene,
                                libelous, blasphemous, defamatory, inciting hatred, terrorism or
                                any similar offence; (ii) infringes or misappropriates the
                                intellectual property rights or violates the privacy rights of
                                any third party (including without limitation, copyright,
                                trademark, patent, trade secret, or other intellectual property
                                right, moral right, or right of publicity); (iii) is in violation
                                or may encourage any manner of acting that would violate any
                                applicable local, state, national and foreign laws, treatises and
                                regulations; or (iv) may drive or encourage any third party to do
                                any of the above.
                            </p>
                        </li>
                        <li>
                            <p>
                                You will not, and will ensure that your Permitted Users will not:
                                (i) abuse the Service; (ii) resell, transfer, grant others
                                permission to use the Service, pledge, lease, rent, or share your
                                rights under this Agreement (including without limitation to any
                                of your affiliates); (iii) modify, remove or amend Volumez&rsquo;s name
                                or logo, update, reproduce, duplicate, copy all or any part of
                                the Service; (iv) make any of the Service available to anyone
                                other than your employees and consultants for use for your
                                benefit as intended pursuant to this Agreement, or use any
                                Service for the benefit of anyone other than you; (v) use the
                                Service in any way that restricts or inhibits the use of the
                                Service; (vi) access or attempt to access any of Volumez&rsquo;s
                                systems, programs or data that are not made available for public
                                use, or attempt to bypass any registration processes on the
                                Service or any of the Service&rsquo;s security and traffic management
                                devices; or (vii) attempt to decompile, disassemble, re-engineer
                                or reverse engineer the Service or otherwise create or attempt to
                                create or permit, allow, or assist others to extract source code
                                of the Service, its structural framework or allow or facilitate a
                                third party, to violate or infringe any rights of Volumez&rsquo;s or
                                others or Volumez policies or the operational or security
                                mechanisms of the Service.
                            </p>
                        </li>
                        <li>
                            <p>
                                When using the Service in conjunction with other third party
                                services, you will comply with the terms of service of such third
                                party services. Volumez shall not be liable for any termination,
                                breach of terms or suspension of service resulting from your use
                                of the Service.
                            </p>
                        </li>
                        <li>
                            <p>
                                You may not access or use the Service if you are a direct
                                competitor of Volumez, or for monitoring the Service&rsquo;s
                                availability, performance or functionality, or for any other
                                benchmarking or competitive purposes.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">SUBSCRIPTION FEES</span>
                    </p>
                    <ol start="11" type="1">
                        <li>
                            <p>
                                In consideration for the right to use the Service under the Terms
                                herein, you will pay subscription fees in the amount and payment
                                terms under the applicable Order Form (the &ldquo;<strong>Subscription
                                    Fees</strong>&rdquo;). You agree that in the event Volumez is unable to
                                collect the Subscription Fees owed to Volumez for the Service,
                                Volumez may take any other steps it deems necessary to collect
                                such fees from you and that you will be responsible for all costs
                                and expenses incurred by Volumez in connection with such
                                collection activity, including collection fees, court costs and
                                attorneys&rsquo; fees. You further agree that Volumez may collect
                                interest at the lesser of 1.0% per month or the highest amount
                                permitted by law on any amounts not paid when due. Except to the
                                extent otherwise expressly stated in this Agreement or in an
                                Order Form, all obligations to pay Subscription Fees are
                                non-cancelable and all payments are non-refundable.
                            </p>
                        </li>
                        <li>
                            <p>
                                Your Subscription Fees are exclusive of taxes, levies, duties or
                                similar governmental assessments of any kind (excluding taxes
                                based on Volumez&rsquo;s income, property and employees). You will be
                                responsible for paying any and all such taxes.
                            </p>
                        </li>
                        <li>
                            <p>
                                Volumez reserves the right to modify the Subscription Fees for
                                the Service under one or more Order Forms, effective upon
                                commencement of the next renewal subscription term of the
                                relevant Order Form(s), by notifying you of such change in
                                writing at least 7 days before the end of the then-current
                                Subscription Term.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">PROPRIETARY RIGHTS; YOUR FEEDBACK</span>
                    </p>
                    <ol start="14" type="1">
                        <li>
                            <p>
                                All parts of the Service are protected by copyrights, trademarks,
                                service marks, patents or other proprietary rights, as a
                                collective work or compilation, pursuant to laws and
                                international conventions. All rights to the Service and
                                derivatives thereof are retained by Volumez. In addition, Volumez
                                retains all rights to aggregated and anonymous data derived from
                                your use of the Service.
                            </p>
                        </li>
                        <li>
                            <p>
                                In the course of using the Service, you or your Permitted Users
                                may provide Volumez with feedback and suggestions regarding the
                                Service. You hereby assign to Volumez ownership in all such
                                feedback and suggestions and all rights therein, without any
                                royalty or accounting obligations to you.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">WARRANTIES; DISCLAIMER</span>
                    </p>
                    <ol start="16" type="1">
                        <li>
                            <p>
                                Each of you and Volumez represent, warrant and covenant to the
                                other that: (a) it has the full corporate right, power and
                                authority to enter into and perform this Agreement, and such
                                execution and performance does not and will not violate any other
                                agreement to which it is a party, and (b) this Agreement
                                constitutes its legal, valid and binding obligation.
                            </p>
                        </li>
                        <li>
                            <p>
                                Volumez provides the service &ldquo;as is&rdquo; and &ldquo;as available&rdquo;, without
                                any warranties and Representations.
                            </p>
                        </li>
                        <li>
                            <p>
                                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, VOLUMEZ
                                EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                                IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTY OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                                NON-INFRINGEMENT. WITHOUT LIMITING THE ABOVE, VOLUMEZ MAKE NO
                                WARRANTY WHATSOEVER WITH RESPECT TO (I) THE SERVICE MEETING YOUR
                                REQUIREMENTS, OR BEING UNINTERRUPTED, CONTINUOUS, TIMELY, OR
                                ERROR OR VIRUS FREE; (II) WHETHER YOUR USE OF THE SERVICE OR THE
                                CONTENT WILL GENERATE ANY RESULTS OR CONSEQUENCES; OR (III)
                                WHETHER YOUR USE OF THE SERVICE IS LAWFUL IN ANY PARTICULAR
                                JURISDICTION.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">PRIVACY AND OTHER POLICIES</span>
                    </p>
                    <p>
                        Use of the Service is also subject to Volumez&rsquo;s Privacy Policy, a link to
                        which is located at the footer on Volumez&rsquo;s website <a  className="text-info" target="_blank" href=
                            "https://volumez.com/app#/legal/privacy-policy">&nbsp;https://volumez.com/app#/legal/privacy-policy</a>
                        . The Privacy Policy, and all policies are incorporated into this
                        Agreement by this reference. Additionally, you understand and agree that
                        Volumez may contact you via e-mail or otherwise with information relevant
                        to your use of the Service, regardless of whether you have opted out of
                        receiving marketing communications or notices.
                    </p>
                </li>
                <li>
                    <p>
                        <span class="underline">CONFIDENTIALITY</span>
                    </p>
                    <ol start="19" type="1">
                        <li>
                            <p>
                                "Confidential Information&rdquo; means all information provided by a
                                party to other party, whether orally or in writing, that is
                                designated as confidential or that reasonably should be
                                understood to be confidential given the nature of the information
                                and the circumstances of disclosure and excluding any information
                                that was or has become publicly available without the receiving
                                party&rsquo;s actions or inactions. Volumez&rsquo;s confidential information
                                includes, without limitation, the Service&rsquo;s features,
                                functionality and performance and your view of the Service. Your
                                Confidential Information includes, without limitation, the
                                Content.
                            </p>
                        </li>
                        <li>
                            <p>
                                Each party will hold the other party&rsquo;s Confidential Information
                                in strict confidence, use it only subject to the terms of this
                                Agreement, allow its use only by the receiving party&rsquo;s employees
                                and consultants who have signed in advance a confidentiality
                                agreement containing terms similar to this Agreement and on a
                                need-to-know basis and pursuant to the terms of this Agreement,
                                not make the other party&rsquo;s Confidential Information available to
                                any third party unless to the extent required by applicable law,
                                implement adequate security measures to ensure against
                                unauthorized access to, use or copying of the other party&rsquo;s
                                Confidential Information, and notify the other party in writing
                                of any misuse of misappropriation of the other party&rsquo;s
                                Confidential Information of which the receiving party may become
                                aware; in each case without derogating from the terms of the
                                Volumez Privacy Policy.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">TERM AND TERMINATION</span>
                    </p>
                    <ol start="21" type="1">
                        <li>
                            <p>
                                The initial subscription term of the Agreement will be as set
                                forth in the Order Form (the "<strong>Initial Subscription
                                    Term</strong>").
                            </p>
                        </li>
                        <li>
                            <p>
                                At the end of the Initial Subscription Term, all your Volumez
                                subscriptions (including any additional subscriptions added to
                                your account) will renew automatically for additional periods
                                equal to the Initial Subscription Term, unless you or Volumez
                                notify the other in writing, at least 30 days prior to the end of
                                the then-current subscription term, that it chooses not to renew
                                (the Initial Subscription Term, together with any renewal
                                subscription terms shall be referred to as the
                                &ldquo;<strong>Subscription Term</strong>&rdquo;).
                            </p>
                        </li>
                        <li>
                            <p>
                                Notwithstanding the foregoing, Volumez may terminate this
                                Agreement immediately and for any reason or no reason, by
                                providing a written notice.
                            </p>
                        </li>
                        <li>
                            <p>
                                Upon termination or expiration of this Agreement; (a) you will
                                cease use of the Service and all rights granted to you under this
                                Agreement will terminate; and (b) Sections ‎3.1, ‎4, ‎5, ‎6, ‎8,
                                ‎9 through ‎12, ‎13, ‎15 and ‎16 survive termination of the
                                Agreement. Thereafter, Volumez may delete any of your Content
                                from its servers or databases.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <span class="underline">LIMITATION OF LIABILITY</span>
                    </p>
                    <p>
                        TO THE FULLEST EXTENT PERMITTED BY LAW, (i) UNDER NO CIRCUMSTANCES WILL
                        VOLUMEZ BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR
                        CONSEQUENTIAL DAMAGES; IN EACH CASE, INCLUDING BUT NOT LIMITED TO,
                        DAMAGES FOR LOSS OF PROFITS, EVEN IF VOLUMEZ BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
                        STRICT LIABILITY OR OTHERWISE; AND (ii) VOLUMEZ AGGREGATE LIABILITY
                        ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE SERVICE WILL NOT
                        EXCEED THE FEES ACTUALLY RECEIVED BY VOLUMEZ FROM YOU UNDER THIS
                        AGREEMENT DURING THE 6 MONTHS PRECEDING THE APPLICABLE CLAIM. THE ABOVE
                        LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE
                        OF ANY LIMITED REMEDY AND ARE FUNDAMENTAL ELEMENTS OF THE BARGAIN BETWEEN
                        THE PARTIES. Because some states and jurisdictions do not allow the
                        exclusion or limitation of liability, the above limitation may not apply
                        to you.
                    </p>
                </li>
                <li>
                    <p>
                        <span class="underline">LIMITATION OF CLAIMS</span>
                    </p>
                    <p>
                        Any claim or cause of action arising out of or related to use of the
                        Service or to the Agreement must be filed within six months after such
                        claim or cause of action arose, or be forever barred.
                    </p>
                </li>

                <li>
                    <p>
                        <span class="underline">INDEMNIFICATION</span>
                    </p>
                    <p>
                        You will indemnify, defend and hold harmless Volumez, its officers,
                        directors, employees, agents and affiliates, from and against all losses,
                        liabilities, claims, obligations, costs and expenses (including
                        reasonable attorneys&rsquo; fees) arising out of or relating to any third-party
                        claims with respect to your use of the Service, including without
                        limitation your Content and any claims against Volumez by your employees,
                        users, consultants, customers, service providers or the like in
                        connection with their use of the Service.
                    </p>
                </li>
                <li>
                    <p>
                        <span class="underline">GOVERNING LAW AND EXCLUSIVE COURTS</span>
                    </p>
                    <p>
                        This Agreement will be governed by laws of the State of Israel without
                        regard to its choice of law or conflicts of law principles. You and
                        Volumez consent to the exclusive jurisdiction and venue in the courts of
                        Tel Aviv, except that temporary relief to enjoin infringement of
                        intellectual property rights may be sought in any court where such
                        infringement has occurred.
                    </p>
                </li>

                <li>
                    <p>
                        <span class="underline">FORCE MAJEURE</span>
                    </p>
                    <p>
                        Neither party will be deemed to be in breach of this Agreement for any
                        failure caused by reasons beyond a party&rsquo;s reasonable control (including
                        without limitation acts of God, war or civil disturbance), and it will
                        notify the other party as soon as practicable in writing of such failure.
                    </p>
                </li>
                <li>
                    <p>
                        <span class="underline">INJUNCTIVE RELIEF</span>
                    </p>
                    <p>
                        You acknowledge that any use of the Service contrary to this Agreement,
                        or any transfer, sublicensing, copying or disclosure of technical
                        information or materials related to the Service, may cause irreparable
                        injury to Volumez, its affiliates, suppliers and any other party
                        authorized by Volumez to resell, distribute, or promote the Service
                        (&ldquo;Resellers&rdquo;), and under such circumstances Volumez, its affiliates,
                        suppliers and Resellers will be entitled to equitable relief, without
                        posting bond or other security, including, but not limited to,
                        preliminary and permanent injunctive relief.
                    </p>
                </li>

                <li>
                    <p>
                        <span class="underline">GENERAL</span>
                    </p>
                    <ol start="25" type="1">
                        <li>
                            <p>
                                This Agreement, the Order Form, the Privacy Policy comprise the
                                entire agreement between you and Volumez, state Volumez&rsquo;s and
                                Volumez&rsquo;s suppliers&rsquo; entire liability and your exclusive remedy
                                with respect to the Service, and supersede all prior agreements
                                pertaining to subject matters of the Agreement, the Order Form,
                                the Privacy Policy, and you specifically confirm that you have
                                not entered into this Agreement relaying on any oral or written
                                public comments made by Volumez regarding future functionality or
                                features of the Service. The terms of any purchase order or
                                similar document will have no effect and are hereby rejected.
                                This Agreement has been prepared in the English language and such
                                version shall be controlling in all respects and any non-English
                                version of this Agreement is solely for accommodation purposes.
                            </p>
                        </li>
                        <li>
                            <p>
                                You and Volumez are independent contractors with respect to each
                                other, and nothing in this Agreement will be construed as
                                creating a partnership, agency, fiduciary or employment
                                relationship or a joint venture between you and Volumez.
                            </p>
                        </li>
                        <li>
                            <p>
                                If any provision of this Agreement is held to be contrary to law,
                                such provision will be construed, as nearly as possible, to
                                reflect the original provision and the other provisions remain in
                                full force and effect.
                            </p>
                        </li>
                        <li>
                            <p>
                                The section titles in this Agreement are solely for convenience
                                and have no legal or contractual significance. No provision of
                                the Agreement will be construed against Volumez but rather will
                                be construed in a neutral manner as terms entered into by a
                                fully-informed party on a voluntary basis after opportunity to
                                confer with advisors and legal counsel about the meaning and
                                effects of the terms of this Agreement.
                            </p>
                        </li>
                        <li>
                            <p>
                                Volumez may revise this Agreement from time to time and at its
                                sole discretion. When such changes are effected, Volumez will
                                publish an updated version on the website: <a  className="text-info" target="_blank" href=
                                    "https://volumez.com/app#/legal/terms-of-service">&nbsp;https://volumez.com/app#/legal/terms-of-service</a>
                                .
                            </p>
                        </li>
                        <li>
                            <p>
                                All modification&rsquo;s to or waivers of any term of this Agreement
                                must be in a writing signed by you and Volumez and expressly
                                reference this Agreement. No waiver of any term of this Agreement
                                will be deemed a further or continuing waiver of such term or any
                                other term, and any failure to assert any right under the
                                Agreement will not constitute a waiver.
                            </p>
                        </li>
                        <li>
                            <p>
                                Volumez suppliers are beneficiaries of the limitations,
                                obligations and restrictions contained in this Agreement that are
                                protective of Volumez or the Service.
                            </p>
                        </li>
                        <li>
                            <p>
                                This Agreement, and any rights granted hereunder, may not be
                                transferred or assigned by a you. Volumez may assign or transfer
                                this Agreement and any Order Form without limitations.
                            </p>
                        </li>
                    </ol>
                </li>
            </ol>
            <p>
                Updated: December 2, 2021
            </p>
        </div>
    )
}
