import React from 'react'
import { Card } from 'reactstrap'

export default function Privacy() {
    return (

        <div className='p-5 text-justify'>


            <p class="d-flex justify-content-center m-1">
                <strong>VOLUMEZ TECHNOLOGIES LTD.</strong>
            </p>
            <p class="d-flex justify-content-center mb5">
                <strong class="m-auto">WEBSITE AND/OR SERVICE PRIVACY AND COOKIE POLICY</strong>
            </p>
            <p>
                <strong>This website and/or the Service are operated by VOLUMEZ
                    TECHNOLOGIES LTD.</strong>
                <br /><em>(All capitalized terms shall have the meanings ascribed to them in
                    the full privacy policy below.)</em>
            </p>

            <p>
                Volumez Technologies Ltd.&rsquo;s privacy policy (the &ldquo;<strong>Privacy
                    Policy</strong>&rdquo; or &ldquo;<strong>Policy</strong>&rdquo;) explains our practices for
                processing Personal Information on our Website and/or the Service.
            </p>
            <p>
                We are committed to protecting privacy and processing Personal
                Information fairly and lawfully in compliance with data protections laws
                applicable to us. You can access our full Privacy Policy below to help
                you understand better how we collect and use your Personal Information.
                The Policy details the types of Personal Information we collect on our
                Website and/or our Service, how we collect it, what we may use it for,
                who we may share it with, how long we may keep it for and what are your
                rights in relation to your Personal Information.
            </p>
            <p>
                Following your first use of the Website and/or Service, you will be asked
                to indicate that you have read this Privacy Policy. Read this policy and
                make sure you fully understand our practices in relation to your Personal
                Information, before you access or use the Website and/or the Service. If
                you read and fully understand this Privacy Policy, and remain opposed to
                our practices, you must immediately leave this Website and/or the
                Service, and avoid or discontinue all use of the Website and/or the
                Service. If you have further questions or concerns regarding this privacy
                policy, please contact us at: <a  className="text-info" target="_blank" href=
                    "https://volumez.com/app#/contact">
                    &nbsp;https://volumez.com/app#/contact</a>.
            </p>
            <p>
                <strong><br /></strong>
            </p>
            <h6 className='m-auto font-weight-bold pb-1'>FULL PRIVACY POLICY</h6>
            <ol>
                <li>
                    <strong>Definitions</strong>
                    <ol className="listOrder">
                        <li>
                            <p>
                                &ldquo;<strong>Information</strong>&rdquo; shall mean Non-PII and PII,
                                together.
                            </p>
                        </li>
                        <li>
                            <p>
                                &ldquo;<strong>Volumez</strong>&rdquo;, &ldquo;<strong>we</strong>&rdquo;,
                                &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo; shall mean Volumez
                                Technologies Ltd.
                            </p>
                        </li>
                        <li>
                            <p>
                                &ldquo;<strong>Non-PII</strong>&rdquo; or &ldquo;<strong>Anonymized
                                    Information</strong>&rdquo; shall mean any information, in any format
                                or media, which is anonymous and/or does not personally identify
                                you as an individual and/or cannot be attributed to you as an
                                individual (such as your browser type). We may use some
                                electronic tools to collect Non-PII, as detailed in Section ‎3
                                below.
                            </p>
                        </li>
                        <li>
                            <p>
                                &ldquo;<strong>Personal Information</strong>&rdquo; or &ldquo;<strong>PII</strong>&rdquo;
                                shall mean any information that identifies, relates to,
                                describes, is capable of being associated with, or could
                                reasonably be linked, directly or indirectly, with a particular
                                individual, or as otherwise defined as such in applicable law.
                            </p>
                        </li>
                        <li>
                            <p>
                                &ldquo;<strong>Terms of Use&rdquo;</strong> shall mean the terms which govern
                                your use of the Website and/or the Service available at <a  className="text-info" target="_blank" href=
                                    "https://volumez.com/app#/legal/terms-of-service">
                                    &nbsp;https://volumez.com/app#/legal/terms-of-service</a>.
                            </p>
                        </li>
                        <li>
                            <p>
                                &ldquo;<strong>Visitor</strong>&rdquo;, &ldquo;<strong>you</strong>&rdquo;,
                                &ldquo;<strong>yours</strong>&rdquo; shall mean a temporary or permanent
                                individual person who enters our Website and/or the Service.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>"Service</strong>" or shall mean Volumez's SaaS platform,
                                which provides data orchestration and block storage as a service.
                            </p>
                        </li>
                        <li>
                            <p>
                                &ldquo;<strong>Website</strong>&rdquo; shall mean our public website at
                                <a  className="text-info" target="_blank" href=
                                    "https://volumez.com/app">
                                    &nbsp;https://volumez.com/app</a> providing marketing materials and
                                professional information regarding our Service for clarity, the
                                term "Website" includes all Content (defined above) in the
                                Website.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Collection of Personal Information</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                <span class="underline">General</span>: The collection of PII
                                commences on the first use of the Website and/or the Service. We
                                collect PII for the purposes detailed in Section ‎4 below.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="underline">PII you may provide us</span>: We collect
                                PII from the information you provide us on the Website and/or the
                                Service. In order to submit the &ldquo;contact us&rdquo; online form on our
                                Website and/or the Service, you are required to provide your
                                name, email address. These means of communication are intended to
                                allow us to contact you and respond to your inquiries. Please
                                note that at all times, you may choose whether or not to provide
                                or disclose Personal Information and please do not provide any
                                more PII than is needed for us to contact you.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="underline">PII we collect or generate</span>: We may
                                use technological tools (detailed in Section ‎5 below) through
                                which we may collect the following PII: persistent identifiers
                                such as IP addresses through the use of &ldquo;cookies&rdquo;.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Anonymized Information</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                In addition to the categories of Personal Information described
                                above, we may also process further anonymized information and
                                data that is not processed by reference to a specific individual.
                                We may collect this Non-PII through the Website and/or the
                                Service in the following ways:
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        Information that your browser sends (&ldquo;<strong>Log
                                            Data</strong>&rdquo;). This Log Data may include, (however is not
                                        limited to): non-identifying information regarding your
                                        operating system, internet browser type, screen resolution,
                                        language and keyboard settings, media's disk and memory size.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We may use automated devices and applications to evaluate
                                        usage of our Website and/or the Service. We use these tools
                                        to help us improve our Website and/or the Service,
                                        performance and user experience. For more information on our
                                        cookies and other means of tracking, please see Section ‎5.1
                                        &ldquo;<em>Cookies</em>&rdquo; below.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We may also engage third parties to track and analyze data or
                                        provide other Services on our behalf. For more information,
                                        please see Section ‎6 &ldquo;<em>Transfer to Third Parties</em>&rdquo;
                                        below.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>The Purposes for which We Collect and Use
                            Information</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                <span class="underline">Your PII shall be used by Us for the
                                    following purposes</span>:
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        to provide and operate the Website and/or the Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to monitor and analyze use of the Website and/or the Service
                                        and study and analyze the functionality of the Website and/or
                                        Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to provide on-going customer assistance, technical support
                                        and maintain the Website and/or the Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to provide Service announcements, notices about employment
                                        opportunities, promotional messages and to market our
                                        Services;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to enforce our Terms of Use, policies and other contractual
                                        arrangements and prevent misuse of the Website and/or the
                                        Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to comply with court orders and warrants and to take any
                                        action in any legal dispute and proceeding;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to better understand your needs, both on an aggregated and
                                        individualized basis, in order to further develop, customize
                                        and improve our Website and/or Service based on Visitors&rsquo;
                                        preferences, experiences and difficulties;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to communicate with you and contact you to obtain feedback
                                        from you regarding the Website and/or the Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to disclose or transfer to third party vendors, service
                                        providers, contractors or agents who perform functions on our
                                        behalf with respect to the Website and/or the Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        to create aggregated statistical data and other aggregated
                                        and/or inferred Non-PII, which we or our business partners
                                        may use to provide and improve our Website and/or the Service
                                        or our business partner&rsquo;s Services; and as otherwise
                                        authorized by you.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                We may use your email address, which you provided us to contact
                                you, when necessary, including in order to send you reminders,
                                offers and to provide you information and notices about the
                                Website and/or the Service. At any time, you may choose (opt out)
                                whether your Personal Information is to be used for sending such
                                marketing materials which are not an essential part of the usage
                                of the Website and/or the Service. You may exercise your choice
                                by contacting us at: <a  className="text-info" target="_blank" href=
                                    "mailto:info@volumez.com">info@volumez.com</a>.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span class="underline">Your Non-PII shall be used by Us for the
                                    same purposes we use Personal Information (where applicable) and
                                    in addition for the following purposes</span>:
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        To monitor and analyze your use of the Website and/or the
                                        Service for technical administration and troubleshooting of
                                        the Website and/or the Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        For commercial research and further development;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Improving the Website and/or the Service by creating system
                                        analytics and statistical data;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Transfer to third party vendors, service providers,
                                        contractors who are working on our behalf for storage,
                                        process and/or back up purposes;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Compile and aggregate statistical trends; and
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        For any other legitimate purpose.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                You hereby warrant and represent that you will not have claim
                                and/or demand, of any kind, regarding the use of the Non-PII for
                                any legitimate purpose.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Technological Tools for Collection of Information</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                <span class="underline">Cookies</span>.
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        A cookie is a small data file that we transfer to your
                                        computer&rsquo;s hard disk for record-keeping purposes. When you
                                        access or use our Website and/or the Service, We may use
                                        industry-standard technologies such as &ldquo;cookies&rdquo;, other
                                        tracking technologies and other local files, which store
                                        certain information on the browser or hard drive of your
                                        computer and/or your mobile telephone device and which will
                                        allow us to distinguish you from other users, enable
                                        automatic activation of certain features, improve Your user
                                        experience and other capabilities and prevent fraudulent or
                                        abusive use of the Website and/or the Service.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                We may use analytics tools to help us understand Visitors&rsquo;
                                behavior on our Website and/or the Service, including by tracking
                                page content, and click/touch, movements, scrolls and keystroke
                                activities. We use Hotjar and Mixpanel. The privacy practices of
                                these tools are subject to their own policies and they may use
                                their own cookies to provide their Services. Further information
                                about the privacy policies of these vendors is available,
                                respectively, at: <a  className="text-info" target="_blank" href=
                                    " https://www.hotjar.com/legal/policies/privacy/">
                                    &nbsp;https://www.hotjar.com/legal/policies/privacy</a> and/or
                                <a  className="text-info" target="_blank" href="https://mixpanel.com/legal/privacy-policy/">
                                &nbsp;https://mixpanel.com/legal/privacy-policy/</a>. Further
                                information about your option to opt-out of these analytics tools
                                is available at: <a  className="text-info" target="_blank" href=
                                    "https://www.hotjar.com/policies/do-not-track/">
                                    &nbsp;https://www.hotjar.com/policies/do-not-track/</a>
                            </p>
                        </li>
                        <li>
                            <p>
                                Depending on your computer settings, you may be allowed to refuse
                                the use of such tools (whether in whole or in part). However,
                                this may affect your ability to use the Website and/or the
                                Service, including to access and use certain features.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Transfer to Third Parties</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                We do not sell, rent or lease your Personal Information. You
                                hereby acknowledge and provide your informed consent, that in
                                connection with the purposes set forth in this Privacy Policy, We
                                may share and/or otherwise transfer the Information to other
                                third parties, as follows:
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        Third party service providers which assist us in the on-going
                                        operation of the Website and/or the Service, including
                                        (without limitations) for storage or hosting of the
                                        Information and/or for the purposes set forth in this Privacy
                                        Policy;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Resellers and Distributors - We work with Volumez and
                                        distributors who may collect your Personal information. Our
                                        engagement with such entities is subject to their contractual
                                        obligation to protect your Personal Information;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        In order to comply with any applicable law or legal requests,
                                        court orders and warrants including from law enforcement
                                        agencies, or when we believe in good faith that disclosure of
                                        Information is necessary to prevent imminent physical harm,
                                        financial loss or to report suspected illegal activity. In
                                        all cases, such Information will only be disclosed in
                                        accordance with applicable laws;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        To take any action in any case of dispute involving you with
                                        respect to your usage of our Website and/or the Service;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        To our affiliates and business partners in connection with
                                        the Website and/or the Service; and
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        In connection with a merger, acquisition, reorganization,
                                        assignment, consolidation, transfer, change of control, sale,
                                        joint venture, or other disposition of all or any portion of
                                        our business, assets or stock. We would share Personal
                                        Information with third parties if we undergo bankruptcy or
                                        liquidation, in the course of such proceedings.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                We may need to disclose Personal Information in response to
                                lawful requests by public authorities or law enforcement
                                officials, including meeting national security or law enforcement
                                requirements. We cooperate with government and law enforcement
                                officials to enforce and comply with the law.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Transfer of Personal Information Outside Your
                            Territory</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                The Information is stored on third party's secured servers
                                located in the United States or other countries that may be
                                outside of your jurisdiction, which may not considered to offer
                                an adequate level of protection as exists under your local laws.
                                By providing us your Personal Information in any medium
                                (including, without limitations, by submitting your Personal
                                Information through the Website and/or in the Services), you
                                hereby acknowledge, and provide your informed consent to such
                                transfer, storing and/or processing of your Personal Information
                                in such locations<strong>.</strong>
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Rights regarding Personal Information</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                You have the right to review your PII that is stored in our
                                database(s) and may have a right, under applicable law, to
                                request the update, amendment and/or deletion of PII or exercise
                                other rights under applicable law, by contacting us at: <a  className="text-info" target="_blank" href=
                                    "https://volumez.com/app#/contact">
                                    &nbsp;https://volumez.com/app#/contact</a>. We will act in accordance with
                                the applicable law, in order to comply with your request.
                            </p>
                        </li>
                        <li>
                            <p>
                                We may retain certain information as deemed required by us in
                                accordance with applicable laws, or for legitimate business
                                reasons, for the duration as required under the applicable laws.
                                In addition, we may delete any Personal Information pursuant to
                                our policies, as in effect from time to time.
                            </p>
                        </li>
                        <li>
                            <p>
                                When you ask us to exercise any of your rights under this Policy
                                and the applicable laws, we may need to ask you to provide us
                                certain credentials to make sure that you are who you claim you
                                are, to avoid disclosure to you of Personal Information related
                                to others and to ask You questions to better understand the
                                nature and scope of Personal Information that you request to
                                access and your legal rights in relation thereto.
                            </p>
                        </li>
                        <li>
                            <p>
                                We may redact from the Personal Information which we will make
                                available to you, any Personal Information related to others.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Security</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                We take the safeguarding of the Information very seriously, and
                                use a variety of methods to try to protect the Information from
                                loss or unauthorized use or access when it is in our possession
                                or control, including reasonable technical and organizational
                                measures that restrict access to the Information. However, we do
                                not promise or guarantee that any Information will be fully
                                protected from unauthorized disclosure or use.
                            </p>
                        </li>
                        <li>
                            <p>
                                We also regularly monitor our systems for possible
                                vulnerabilities and attacks, and regularly seek new ways and for
                                further enhancing the security of our Website and/or the Service
                                and protection of our Visitors&rsquo; privacy.
                            </p>
                        </li>
                        <li>
                            <p>
                                You should take steps to protect against unauthorized access to
                                your passwords, phone, and computer by, among other things,
                                signing off after using a shared computer, choosing a robust
                                password that nobody else knows or can easily guess, and keeping
                                your log-in and password private. In addition, you should take
                                steps to protect against unauthorized access to Personal
                                Information stored on your devices as well as defining limited
                                access rights to such information on a need to know basis.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Data Retention</strong>
                    </p>
                    <ol>
                        <li>
                            <p>
                                We retain different types of Information for different periods,
                                depending on the purposes for processing the Personal
                                Information, our legitimate business purposes as well as pursuant
                                to legal requirements under the applicable law.
                            </p>
                        </li>
                        <li>
                            <p>
                                We may retain Personal Information for as long as necessary to
                                support the collection and the use purposes under this Policy and
                                for other legitimate business purposes, for example, for storing
                                Personal Information for documentation, cyber-security management
                                purposes, legal proceedings and tax issues. We may store
                                aggregated Non-PII without time limit.
                            </p>
                        </li>
                        <li>
                            <p>
                                In any case, as long as you use the Website and/or the Service,
                                we will keep Information about you, unless we are legally
                                required to delete it, or if you exercise your legal rights to
                                delete the information.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>Links to Third Party Sites.</strong> The Website and/or the
                        Service may enable interaction with or contain links to other third
                        party websites or applications, for which privacy practices we are
                        not responsible. Please read the terms and conditions and privacy
                        policy of each such third party that you choose to use or interact
                        with.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Children</strong>. The Website and/or the Service are not
                        intended for minors under the age of 18 years and we do not intend to
                        collect Personal Information from anyone we know to be under 18
                        years. We rely on you to ensure that the data subject must be above
                        the age of 18. If these age requirements are not met, you are
                        required to obtain the consent of the parent or guardian to provide
                        and process information in accordance with this Privacy Policy;
                        lacking such consent, you may not use the Website and/or the Service.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Changes to the Privacy Policy</strong>. We reserve the right
                        to change this Privacy Policy at our sole discretion. We will inform
                        you of any such change by posting a new Privacy Policy on the Website
                        and/or the Service; the effective date of any change of Privacy
                        Policy will be clearly marked on each new Privacy Policy posted.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Governing Law.</strong> This Privacy Policy shall be governed
                        by and construed in accordance with the laws of the State of Israel,
                        excluding its choice of law principals. Disputes arising in
                        connection with this Privacy Policy shall be subject to the exclusive
                        jurisdiction of the competent courts in Tel-Aviv-Jaffa, Israel.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Contact Us</strong>. If you have any concerns or questions
                        about this Privacy Policy, please contact us at: <a  className="text-info" target="_blank" href=
                            "mailto:info@volumez.com">info@volumez.com</a>.
                    </p>
                </li>
            </ol>
            <p>
                Last Updated: December 1, 2021
            </p>
            <p>
                Copyright © 2021, Volumez Technologies Ltd. All rights reserved
            </p>
        </div>
    )
}
