import React from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Alert } from 'reactstrap';

export default function StepC(props) {

  return (
    <Container className="justify-content-md-center">

      <Row className="justify-content-md-center">
        <Col>
          <Container fluid className="align-items-center d-flex mb-3">
            <HiCheckCircle size="5em" color="green" className='m-auto' />
          </Container>

          <Alert variant='success' className="m-auto mb-3 w-75">
            Hello {props.userData.name},<br />
            Welcome to Volumez, and get ready to engineer data infrastructure for your cloud workloads!  Through a simple management experience (or using your Infrastructure as Code toolchain), you will be composing next level enterprise performance with new economics unmatched in the industry – expanding and accelerating the value of workloads driving your business.  Built on a unique cloud-aware architecture, Volumez tackles latency, scalability, and cost challenges by establishing direct Linux data paths, ensuring exceptional performance and latency.
            <br/>
            Please check your email for instructions to activate your account.
            <br/>
            The Volumez Team
          </Alert>
        </Col>
      </Row >

    </Container >
  )
}
