import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { Col, Row, FormGroup, Label, CustomInput } from "reactstrap";
import ErrorMsg from './ErrorMsg';
import { Tooltip } from '@material-ui/core';
import Terms from './Terms';
import Privacy from './Privacy';

export default function StepB(props) {

  const [fieldsInvalid, setFieldInvalid] = useState({ email: false, name: false, password: false, passwordConf: false })
  const [isEmailValid, setEmailInvalid] = useState(false);
  let agreement = props.agreement

  useEffect(() => {
    if (props.isClicked) {

      const fields = Object.keys(fieldsInvalid)
      const tmpFieldsInvalid = { email: true, name: true, password: true, passwordConf: true }
      fields.forEach(field => {
        if (props.errors[field] !== '') {
          tmpFieldsInvalid[field] = true;
        } else {
          tmpFieldsInvalid[field] = false;
        }
      })
      setFieldInvalid(tmpFieldsInvalid)
    }

    if (props.isClicked && props.errors.email !== '') {
      setEmailInvalid(true)
    }
    else {
      setEmailInvalid(false)
    }
  }, [props])

  return (
    <Form>
      <Row >
        <Col md={6} className='mt-3' >
          <FormGroup className='mb-1'>
            <Label for="email" >Work Email</Label>
            <Form.Control type="email"
              name="email"
              id="exampleEmail"
              placeholder="Enter email"
              defaultValue={props.userData.email}
              onChange={(e) => props.validateField(e)}
              isInvalid={isEmailValid}
              size='sm'
            />
            {props.isClicked ? <ErrorMsg className='mt-1' msg={props.errors.email} /> : <div>&nbsp;</div>}

          </FormGroup>
        </Col>
        <Col md={6} className='mt-3'>
          <FormGroup className='mb-1'>
            <Label for="name">Full Name</Label>
            <Form.Control type="text" defaultValue={props.userData.name} placeholder="Enter full name" size='sm' name='name' onChange={(e) => props.validateField(e)} isInvalid={fieldsInvalid.name} />
            <ErrorMsg msg={props.errors.name} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className='mb-1'>
            <Label for="password"> Password</Label>
            <Tooltip title="Passwords must be at least 8 characters long and contain lowercase letters, uppercase letters, numbers, and non-alphanumeric characters">
              <Form.Control type="password" defaultValue={props.userData.password} placeholder="Enter password" size='sm' name='password' onChange={(e) => props.validateField(e)} isInvalid={fieldsInvalid.password} />
            </Tooltip>
            <ErrorMsg msg={props.errors.password} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className='mb-1'>
            <Label for="examplePasswordRep">
              Confirm Password
            </Label>
            <Tooltip title="Passwords must be at least 8 characters long and contain lowercase letters, uppercase letters, numbers, and non-alphanumeric characters">
              <Form.Control type="password" defaultValue={props.userData.passwordConf} placeholder="Enter password confirmation" size='sm' id='passwordConf' name='passwordConf' onChange={(e) => props.validateField(e)} isInvalid={fieldsInvalid.passwordConf} />
            </Tooltip>
            <ErrorMsg msg={props.errors.passwordConf} />
          </FormGroup>
        </Col>
      </Row>
      <CustomInput type="checkbox" id="userAgreement" name="checkbox" checked={agreement}
        label={<Label className='mb-0'>
          I agree to Volumez’s
          <a href="#/" className="text-info" onClick={() => props.modal({ display: true, title: "Terms", text: <Terms></Terms> })}> Terms of service </a>  and
          <a href="#/" className="text-info" onClick={() => props.modal({ display: true, title: "Privacy", text: <Privacy></Privacy> })}> Privacy Policy. </a>
        </Label>} onClick={() => props.setAgreement(!agreement)} />
      {(props.isClicked && !agreement) ? <ErrorMsg className='mt-1' msg='Please confirm you agree to the terms and privacy policy.' /> : <div>&nbsp;</div>}
    </Form>
  )
}
