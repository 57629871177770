import Slider from "react-slick";
import image from "../assets/utils/images/originals/HPC-Top.png";

export default function SideImage() {
  return (
    <div className="slider-light w-100">
      <Slider>
        <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark ">
          <img className="side-img" src={image}></img>
        </div>
      </Slider>
    </div>
  )
}
