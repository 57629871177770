import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainForm from './pages/MainForm';
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/base.scss";

//symbolic link created for assets <<===>> ..\..\dashboard\src\assets
// mklink /d assets ..\..\dashboard\src\assets  ----->command to do on command prompt
ReactDOM.render(
  <React.StrictMode>
    <MainForm /> 
  </React.StrictMode>,
  document.getElementById('root')

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

);