
function ErrorMsg(props) {
    return (
        <div className="text-danger"> 
            {props.msg} &nbsp;
        </div>
    )
}

export default ErrorMsg;

