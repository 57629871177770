import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';


function EULA(props) {
    const [show, setShow] = useState(props.show);

    const handleClose = () => {
        setShow(false);
        props.closeModal(props.name);
    }

    useEffect(() => {
        if (props.show) {
            setShow(props.show)
        }
    }, [props]);

    return (
          <Modal dialogClassName={props.dialogClassName} show={show} onHide={handleClose} scrollable={true}>
            <Modal.Header >
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <p>{props.body}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button  color="primary" onClick={() => handleClose()} >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>   
    );
}


export default EULA;

